html {
  background-color: black;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image {
  position: relative;
  height: 100vh; 
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100vh;
  width: 100%;
}

.content span.img-txt {
  text-transform: uppercase;
  color: #fff;
  padding: 1rem;
  font-size: 1.5rem;
  letter-spacing: 10px;
}

h3 {
  letter-spacing: 6px;
  text-transform: uppercase;
  font: 1.3rem;
  text-align: center;
}

.text-box {
  text-align: center;
  padding: 3rem 1rem;
  text-align: justify;
}

p {
  text-align: center;
}