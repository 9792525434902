.music {
    background-color: black;
    color: white;
}

.musicbox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    margin: auto;
    min-height: 210px;
}

.kms {
    float: left;  
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: hidden;
    background: transparent;
}

.playdead {
    float: right;
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: hidden;
    background: transparent;
}

.parallaxBg h3 {
    color: white;
    transform: translateY(5vh);
}